export const en = {
  overview: "Overview",
  changeLanguage: "Change language",
  users: "Users",
  newRequest: "New request",
  myRequests: "My requests",
  requests: "Requests",
  priceOffers: "Offers",
  collapse: "Collapse",
  whatsappNumber: "Whatsapp number",
  emailAddress: "Email address",
  departure: "From (city, country code)",
  arrival: "To (city, country code)",
  packingType: "Packing type",
  containerType: "Container type",
  quantity: "Quantity",
  container: "Container",
  packageName: "Package name",
  packageType: "Package type",
  unitName: "Unit name",
  dimensions: "Dimensions",
  weight: "Weight",
  name: "Name",
  specifics: "Specifics",
  clientNumber: "Customer name (or number)",
  details: "Other details about the request (Optional)",
  assignedManager: "Assigned manager",
  overviewTitle: "Request for Quotes Dashboard",
  assigneeId: "Assignee Id",
  requestQuoteId: "Request Id",
  assignDate: "Assign date",
  unassignDate: "Unassign date",
  assignee: "Assignee",
  assigneeEmail: "Assignee Email",
  search: "Search",
  currentlyAssigned: "Currently assigned",
  createdDateStart: "Created date start",
  createdDateEnd: "Created date end",
  updatedDateStart: "Updated date start",
  updatedDateEnd: "Updated date end",
  newQuoteRequest: "New quote request",
  justNow: "Just now",
  yesterday: "Yesterday",
  minutesAgo: "minutes ago",
  hoursAgo: "hours ago",
  daysAgo: "days ago",
  monthsAgo: "months ago",
  yearsAgo: "years ago",
  lastMonth: "last month",
  lastYear: "last year",
  requestsTitle: "List of requests for quote",
  noAgentAssigned: "No agent assigned",
  fromDotted: " from : ",
  status: "Status",
  createdDate: "Created date",
  offerId: "Offer Id",
  totalPrice: "Total price",
  clientApproval: "Customer approval",
  noEmail: "No email",
  userAssignmentTitle: "Manage users who can assign",
  email: "Email address",
  id: "Identifier",
  canAssign: "Can assign",
  cannotAssign: "Cannot assign",
  cancelAssign: "Cancel assign role",
  assignAsManager: "Assign as manager",
  notAdministratorForAssign: "You cant manage the users because you are not an administrator. Please contact the administrator so he can grant you the desired role.",
  createRequest: "Create the request",
  requestNotAssignedCurrentUser: "This request will not be assigned to the current user, you need to grant him the permission in",
  addContainer: "Add the container",
  add: "Add",
  generatePriceOffer: "Generate price offer",
  editRequest: "Save the request",
  changeStatus: "Change the status",
  addCommentNote: "Add a comment/note",
  listNotes: "List of notes",
  updateManager: "Update the manager",
  removeManager: "Remove the manager",
  askInformation: "Ask for information",
  pleaseFillForm: "Please fill in the form and click the button to send your message.",
  subject: "Subject",
  content: "Content",
  send: "Send",
  close: "Close",
  changeRequestStatus: "Change the request status",
  pleaseChooseOptions: "Please choose one of the following options as the status of the request.",
  statusList: "Status list",
  statusMessage: "Status message (this message will be sent to the requester, leave empty to not send a mail)",
  validate: "Validate",
  pleaseFillAddNote: "Please fill in the field below and click the button to add note.",
  generalNote: "General note",
  listNotesRequest: "List of notes of request N°",
  date: "Date",
  noteType: "Note type",
  deleteNote: "Delete this note",
  departureDate: "Departure date",
  arrivalDate: "Arrival date",
  loadingDate: "Loading date",
  loadingCity: "Loading city",
  haulageType: "Haulage type (loading timing)",
  anyType: "Any type",
  selectOfferMessage: "You can select an offer by clicking on his row. You have to select at least one seafreight for your offer.",
  listSeaFreightsPricingOffers: "List of sea freights pricing offers",
  listHaulagesPricingOffers: "List of haulages pricing offers",
  listMiscPricingOffers: "List of miscellaneous pricing offers",
  selectedSeafreight: "Selected sea freight",
  selectedHaulage: "Selected haulage",
  selectedMisc: "Selected miscellaneous",
  marginPercent: "Margin",
  reductionPercent: "Reduction",
  extraFee: "Extra Fee",
  detailsOffer: "Content of the offer",
  back: "Back",
  skip: "Skip",
  sendOfferValidation: "Send the offer to validation",
  nextStep: "Next step",
  allTypes: "All types",
  allStatus: "All status",
  requestAssignedTo: "This request will be assigned to",
  byDefault: "by default.",
  createNewRequest: "Create a new request",
  manageRequestQuote: "Manage a request for quote N°",
  doYouThinkInformation: "Do you think this request need more information?",
  askMoreInformation: "Ask for more information",
  notDefined: "Not defined",
  approvePriceOffer: "Approve a price offer",
  youSeeDetailsPriceOffer: "You can see the details about our price offer down below",
  selectedContainers: "Selected containers",
  sentBackComment: "Comment to send back",
  statusOfferIs: "The status of this offer is",
  acceptOffer: "Accept the offer",
  rejectOffer: "Reject the offer",
  carrier: "Carrier",
  carrierAgent: "Carrier agent",
  departurePort: "Departure port",
  arrivalPort: "Destination port",
  frequency: "Frequency",
  transitTime: "Transit time",
  day: "day",
  days: "days",
  prices: "Freight per unit",
  haulier: "Haulier",
  loadingPort: "Loading port",
  dischargePort: "Discharge port",
  freeTime: "Free time",
  multiStop: "Multi stop",
  overtimeTariff: "Overtime tariff",
  overtimeTariff2: "Overtime tariff",
  unitTariff: "Haulage per unit",
  validUntil: "Valid until",
  destinationPort: "Destination port",
  supplier: "Supplier",
  searchOffers: "Search for offers",
  listOffers: "List of offers",
  sendOffer: "Review+send offer",
  loadingText: "Loading...",
  typeSomething: "No results, type something...",
  typeSomethingSearch: "Type something to search...",
  generatedPriceOffers: "Sent price offers",
  errorHappened: "An error happened during the operation.",
  requestEditedSuccess: "Your request has been edited with success.",
  managerAssignedRequest: "The manager has been assigned to this request.",
  selectManagerFirst: "You must select a request manager first.",
  managerRemovedRequest: "The manager has been removed from this request.",
  requestStatusUpdated: "Your request's status has been updated with success.",
  messageSuccessSent: "The message has been successfully sent.",
  contentEmpty: "The content field is empty, please fill it.",
  commentSuccessAdded: "The comment/note has been successfully added.",
  priceFieldsEmpty: "You need to select containers first.",
  offerSuccessSent: "The offer has been successfully sent.",
  offerSuccessCreated: "The offer has been successfully created.",
  byOmnifreight: "by Omnifreight",
  byClient: "by the customer",
  statusIs: "The status of this offer is",
  approveOffer: "Approve and send the offer",
  updateOffer: "Update the offer",
  manageOffer: "Manage offer",
  priceOfferRejected: "Your price offer has been rejected with success.",
  priceOfferApproved: "Your price offer has been approved with success.",
  priceOfferUpdated: "Your price offer has been updated with success.",
  requestCreated: "Your request has been created.",
  requestCreatedAssigned: "Your request has been created with success.",
  fieldsEmpty: "One or many fields are empty, please fill the fields from, to, customer name and select at least one container.",
  emailNotValid: "The email is not valid, please verify it.",
  errorHappenedRequest: "An error happened when we were sending your request.",
  needFillFields: "You need to fill the fields unit name, weight and dimensions.",
  offerDeleted: "The offer has been deleted with success.",
  orderDeleted: "The order has been deleted with success.",
  confirmDeletion: "Confirm the deletion",
  areYouSureDelete: "Are you sure you want to delete this offer? This operation cant be cancelled.",
  areYouSureDeleteOrder: "Are you sure you want to delete this order? This operation cant be cancelled.",
  delete: "Delete",
  privacyPolicy: "Privacy Policy",
  introduction: "Introduction",
  dataPersonal: "Personal Data",
  dataTransmitted: "Data transmitted directly\nThis data is the information you directly provide to us, either through a contact form or through direct email contact. The fields \"first name and last name\", \"company or organization\", phone number, and \"email\" are mandatory in the contact form.",
  dataCollectedAutomatically: "Data collected automatically\nDuring your visits, once you give your consent, we may collect \"web analytics\" information regarding your browsing, the duration of your visit, your IP address, and your browser type and version. The technology used is cookies.",
  dataUsage: "Data Usage",
  dataDirectlyProvided: "The data you directly provide us with is used to contact you again and/or in relation to the request you make to us. The \"web analytics\" data is collected anonymously (by recording anonymous IP addresses) by Google Analytics and allows us to measure the audience of our website, the visits, and any errors in order to constantly improve the user experience. This data is used by OMNIFREIGHT, the data controller, and will never be passed on to third parties or used for purposes other than those detailed above.",
  legalBasis: "Legal Basis",
  dataCollectedConsent: "Personal data is only collected after the mandatory consent of the user. This consent is validly obtained (buttons and checkboxes), free, clear, and unambiguous.",
  retentionPeriod: "Retention Period",
  dataStoredMaxPeriod: "The data will be stored for a maximum period of 3 years.",
  cookies: "Cookies",
  cookiesList: "Here is the list of cookies used and their purpose:",
  googleAnalyticsCookies: "Google Analytics Cookies (exhaustive list): Web analytics",
  otherCookies: "Other Cookies: Keeps track of your acceptance of cookies to avoid bothering you during your next visit.",
  rightsPersonalData: "Your Rights Regarding Personal Data",
  accessModificationDeletion: "You have the right to access, request modification, or deletion of all your personal data. You can also withdraw your consent for the processing of your data.",
  dataProtectionContact: "Data Protection Delegate Contact",
  emailContact: "Email: transport@omnifreight.eu - Phone Number: (+32)3.295.38.82",
  downloadBrochure: "Download our brochure",
  pleaseFillFormRequest: "Please fill in the form and click the button to send a request for a quote.",
  download: "Download",
  congratulations: "Congratulations!",
  successRequestQuoteMessage: "Your request has been successfully sent, please check your email or your spam to get your tracking number. We will revert to you soon with the cost and best options for your shipment.",
  checkCaptcha: "You must check the captcha before sending your request.",
  seaShipments: "Sea shipments",
  airShipments: "Air shipments",
  becomeReseller: "Become a reseller",
  jobOpportunities: "Job opportunities",
  iUnderstood: "I understood",
  websiteUsesCookies: "This website uses cookies. By continuing to use this site, you agree to their use. For more details, please see our",
  login: "Login",
  bannerTitle: "We organize the shipment of your goods to Africa from all over the world!",
  requestQuote: "Request a quote",
  contactManager: "Contact a manager",
  bannerMessage1: "Would you like to receive a quotation for a shipment of goods?",
  bannerMessage2: "Would you like an Omnifreight manager to contact you?",
  bannerMessage3: "Want to see more information about Omnifreight?",
  cargoPickup: "Where do you want us to pickup your cargo?",
  cargoDeliver: "Where do you want us to deliver your cargo?",
  cargoTypeShip: "Which type of cargo do you want to ship?",
  numberUnitsShip: "How many units of do you want to ship?",
  shareOtherDetails: "Do you want to share other details regarding your needs? (Optional)",
  continue: "Continue",
  pleaseProvideContact: "Please provide your whatsapp number and /or your email address",
  topicsInformation: "What topics would you like information on ?",
  enterDetails: "Enter the details of your need",
  viewProfile: "View profile",
  inviteMembers: "Invite members",
  pageNotExist: "Sorry, this page does not exist.",
  requestTracking: "Request tracking",
  trackingCodeNotDefined: "The tracking code is not defined.",
  detail: "Details",
  requestDate: "Request date",
  requestStatus: "Request status",
  weAreWorkingOnRequest: "We are working on your request and will revert to you soon with the cost and best options for your shipment.",
  trackingNumber: "Tracking number",
  trackMyRequest: "Track my request",
  customerTitle: "What our customers think...",
  customerRole1: "Importer in Mauritania, Senegal, Ivory Coast, Republic of Congo and Angola.",
  customerMessage1: "I am very happy with the work of Omnifreight and every day I am more motivated to work with them. It is an honest and decent society and they have become like brothers.",
  customerRole2: "Importer to the Republic of Congo.",
  customerMessage2: "Thank you for your responsiveness and professionalism. Always satisfied with Omnifreight",
  assigneeCreateSuccess: "Your new assignee has been created with success.",
  cantFindRequestAssigned: "We cant find a request assigned to you",
  noRequests: "No requests have been found.",
  operationSuccess: "Your operation has been done with success.",
  informationReceived: "Thanks, we have received your information.",
  itsEaseFillForm: "It's ease, just fill in the form and click “continue” at the bottom right to send a request for a quote.",
  requestSuccessSent: "Your request has been sent with success.",
  searchResultsFor: "Search results for",
  listRequestsQuote: "List of requests for quote",
  allRightsReserved: "All rights reserved",
  fcl: "FCL",
  breakbulk: "Breakbulk/LCL",
  roro: "Unit RoRo",
  descriptionEnAttente: "Awaiting processing",
  descriptionValider: "Quote approved by employee",
  descriptionRejeter: "Quote rejected by employee",
  descriptionEnCoursDeTraitement: "Quote in processing",
  descriptionEnTransit: "Goods in transit",
  descriptionEnDouane: "Goods in customs clearance",
  descriptionLivraisonEnCours: "Goods in delivery process",
  descriptionLivre: "Goods delivered to the client",
  descriptionAnnule: "Quote request has been canceled",
  descriptionRetour: "Goods returned to sender",
  descriptionProbleme: "Issue encountered during transport, to be resolved",
  descriptionEnAttenteDeFacturation: "Awaiting billing after delivery",
  haulageType1: "On trailer, direct loading",
  haulageType2: "On trailer, Loading with Interval",
  haulageType3: "Side loader, direct loading",
  haulageType4: "Side loader, Loading with Interval, from trailer to floor",
  haulageType5: "Side loader, Loading with Interval, from floor to trailer",
  searchSeafreight: "Search seafreight",
  selectSeafreight: "Select seafreight",
  searchHaulage: "Search haulage",
  selectHaulage: "Select haulage",
  selectMisc: "Select miscellaneous",
  optional: "Optional",
  hour: "hour",
  hours: "hours",
  noResults: "No results found",
  every: "Every",
  EUR: '€',
  length: "Length",
  width: "Width",
  height: "Height",
  fieldNeedTobeFilled: "You need to fill the fields required",
  clientName: "Customer name",
  client: "Customer",
  costPrices: "Cost per unit",
  quoteNumber: "Quote number",
  lastUpdated: "Last updated",
  comment: "Comment or remarks",
  noComment: "No comment",
  seafreights: "Seafreights",
  listSeafreights: "List of sea freights prices",
  created: "Created date",
  deleteRow: "Delete this row",
  editRow: "Edit this row",
  createRow: "Add a row",
  areYouSureDeleteRow: "Do you really want to delete this row? Be careful, this action cannot be undone.",
  accept: "Confirm",
  currency: "Currency",
  rowDeletedSuccess: "This row has been deleted with success",
  rowDeletedError: "An error occured, the system failed to delete this row",
  inDays: "in days",
  everyxDays: "every x days",
  serviceName: "Service",
  containers: "Containers",
  successCreated: "The element has been created with success",
  price: "Price",
  haulages: "Haulages",
  listHaulages: "List of haulages prices",
  emptyPickupDepot: "Empty pickup depot",
  unitTariff2: "Haulage tariff",
  freeTime2: "Free time at loading place (in hours)",
  miscellaneous: "Miscellaneous",
  labelEnAttente: "Waiting",
  labelValider: "Validated",
  labelRejeter: "Rejected",
  labelEnCoursDeTraitement: "Pending",
  labelEnTransit: "In transit",
  labelEnDouane: "In customs",
  labelLivraisonEnCours: "Delivery in progress",
  labelLivre: "Delivered",
  labelAnnule: "Cancelled",
  labelRetour: "Returned",
  labelProbleme: "Problem",
  labelEnAttenteDeFacturation: "Waiting for billing",
  fileSent: "Attachments",
  fieldsEmptySeafreight: "You must fill all the required fields and choose at least one service",
  fieldsEmptyHaulage: "You must fill all the required fields",
  listMiscellaneous: "List of miscellaneous prices",
  newSeafreightPrice: "Add seafreight price",
  newHaulagePrice: "Add haulage price",
  newMiscellaneousPrice: "Add miscellaneous price",
  listServices: "List of services",
  withShipment: "With shipment",
  mailLanguage: "Initial message with language",
  youNeedSelectHaulage: "You need to select a haulage offer before going to the next step",
  youNeedSelectSeafreight: "You need to select a sea freight offer before going to the next step",
  msgValidateHaulageStep: "You need to fill the fields loading city and haulage type",
  msgValidateStartStep: "The fields departure date, containers and destination port cannot be empty, fill them",
  pricing: "Pricing",
  assigneeCreatedSuccess: "Operation success",
  messageModal: "Message",
  reload: "Reload",
  labelNew: "New",
  requestHaulagePrice: "Request haulage price",
  requestSeafreightPrice: "Request seafreight price",
  priceRequestFCL: "Price request FCL",
  priceRequestHaulage: "Price request haulage",
  portLoading: "Port of loading",
  portDischarge: "Port of discharge",
  commodities: "Commodities",
  etd: "Estimate time of departure",
  recipients: "Recipients",
  deliveryPort: "Port of loading",
  errorSelectRecipient: "You need to select at least one recipient before sending the mail",
  mailSentTo: "Mail sent to : ",
  templates: "Templates",
  listTemplates: "List of templates",
  newTemplate: "New template",
  createdBefore: "Created before",
  createdAfter: "Created after",
  currentVersion: "Current version",
  tags: "Tags",
  listContainers: "List of containers",
  containersQuantities: "Containers and quantities",
  variables: "Add a variable",
  errorHappenedVersion: "An error happened, try to change the version before validating",
  successEdited: "The element has been modified with success",
  cantDelete: "You cant delete this element",
  pricesContainers: "Prices by containers",
  deleteRowHaulage: "Delete haulage price",
  editRowHaulage: "Edit haulage price",
  createRowHaulage: "Add haulage price",
  deleteRowSeafreight: "Delete seafreight price",
  editRowSeafreight: "Edit seafreight price",
  createRowSeafreight: "Add seafreight price",
  deleteRowMisc: "Delete miscellaneous price",
  editRowMisc: "Edit miscellaneous price",
  createRowMisc: "Add miscellaneous price",
  selectedTemplate: "Select a template",
  reloadIndication: "Please, click the reload button if you want to actualize the data after some modifications",
  addressCountry: "Address country",
  countryCode: "Country code",
  createNewPort: "New port",
  createNewContact: "New contact",
  createNewCarrier: "New carrier",
  createNewHaulier: "New haulier",
  createNewHaulage: "New haulage",
  priceType: "Type of price",
  lumpSum: "Margin lump sum",
  margin: "Margin",
  salesPrice: "Sales price",
  totalPurchase: "Total purchase",
  copyRowSeafreight: "Copy seafreight price",
  messageOkGeneralMiscs: "Your general misc services have been recorded!",
  included: "included",
  seafreight: "Seafreight",
  haulage: "Haulage",
  masterdata: "Masterdata",
  services: "Services",
  servicesTypesId: "Types of services",
  newService: "Create a new service",
  deleteRowService: "Delete a service",
  editRowService: "Edit a service",
  createRowService: "Create a service",
  hscodes: "HS Codes",
  newHSCode: "New HS code",
  deleteRowHSCode: "Delete a HS code",
  editRowHSCode: "Edit a HS code",
  createRowHSCode: "Create a HS code",
  hscodeName: "HS Code name",
  listHSCodes: "List of HS Codes",
  products: "Products",
  newProduct: "New product",
  deleteRowProduct: "Delete a product",
  editRowProduct: "Edit a product",
  createRowProduct: "Create a product",
  productName: "Product name",
  listProducts: "List of products",
  listPorts: "List of ports",
  newPort: "Create a new port",
  deleteRowPort: "Delete a port",
  editRowPort: "Edit a port",
  createRowPort: "Create a port",
  portName: "Port name",
  ports: "Ports",
  listContacts: "List of contacts",
  newContact: "Create a new contact",
  deleteRowContact: "Delete a contact",
  editRowContact: "Edit a contact",
  createRowContact: "Create a contact",
  contactName: "Contact name",
  contacts: "Contacts",
  categories: "Categories",
  contactNumber: "Number",
  phone: "Phone number",
  country: "Country",
  serviceTypesId: "Services types",
  newHaulage: "New haulage",
  newSeafreight: "New seafreight",
  rateRequest: "RATE REQUEST",
  rateRequestHaulage: "RATE REQUEST HAULAGE",
  purchasePrice: "Purchase Price",
  profit: "Profit",
  salePrice: "Sale price",
  listFiles: "List of files",
  newFile: "Create a new file",
  deleteRowFile: "Delete a file",
  editRowFile: "Edit a file",
  createRowFile: "Create a file",
  uploadFile: "Upload new file",
  additionals: "additional",
  customerRequest: "Customer request", 
  loadingOf: "Loading of ",
  includedForEachContainer: " hours included for each container, then ", 
  byHourIndivisible: " EUR per indivisible hour.",
  or: "OR", 
  offer: "Offer", 
  files: "Files",
  fileName: "File name",
  contentType: "Content type", 
  size: "Size in bytes",
  uploadedAt: "Uploaded at",
  downloadFile: "Download file",
  file: "File",
  pendingRequests: "Pending requests",
  listPendingRequests: "List of pending requests", 
  createOffer: "Create the offer",
  trip: "Route",
  from: "Departure",
  to: "Arrival",
  messageSentCustomer: "Message sent to the customer",
  newQuoteOffer: "New quote offer",
  yourAttachments: "The message will be sent with the following attachments", 
  noAttachments: "The message does not have attachments", 
  updateOption: "Update",
  selectOption: "Select",
  deleteOption: "Delete",
  newOption: "New option",
  compareOptions: "Compare options",
  youNeedOptions: "You need to save some options to send",
  saveOption: "Save option",
  resendOffer: "Resend the offer",
  goToOptions: "Go to options",
  selectOptionOffer: "I want the offer",
  refuseOffers: "Refuse all offers",
  systemLanguage: "Mail language",
  langtextfr: "French",
  langtexten: "English",
  confirmationOffer: "Confirmation of the selected offer",
  endMailWord: "Sincerely",
  hello: "Hello",
  confirmationOfferText: "We have taken your choice into account and we are happy to confirm that we will proceed with the reservation of your expedition according to the following terms : ",
  confirmationOfferThanks: "Thank you for taking the time to choose the offer that best suits your needs for shipping your cargo.",
  miscDetails: "Miscellaneous details",
  seafreightDetails: "Seafreight details",
  haulageTariff: "Haulage Tariff",
  seafreightTariff: "Seafreight Tariff",
  miscTariff: "Miscellaneous Tariff",
  totalUnitPrice: "Total Unit Price",
  details2: "Details",
  category: "Category",
  serviceItem: "Service Item",
  value: "Value",
  simpleSearch: "Simple search",
  advancedSearch: "Advanced search",
  trackingOptions: "You can obtain information about your order using this number : ",
  orders: "Shipments",
  listOrders: "List of shipments",
  orderNumber: "Number",
  fiscalYear: "Fiscal",
  orderDate: "Date",
  seller: "Seller",
  buyer: "Buyer",
  customer: "Customer",
  textEtd: "ETD",
  textEta: "ETA",
  ship: "Ship",
  shippingLine: "Shipping line",
  ratio: "Ratio",
  vessel: "Vessel",
  ships: "Ships",
  newShip: "New ship",
  deleteRowShip: "Delete a ship",
  editRowShip: "Edit a ship",
  createRowShip: "Create a ship",
  shipName: "Ship name",
  listShips: "List of ships",
};
