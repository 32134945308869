export const fr = {
  overview: "Vue d'ensemble",
  changeLanguage: "Changer de langue",
  users: "Utilisateurs",
  newRequest: "Nouvelle demande",
  myRequests: "Mes demandes",
  requests: "Demandes",
  priceOffers: "Offres",
  collapse: "Décaler",
  whatsappNumber: "Numéro whatsapp",
  emailAddress: "Adresse mail",
  departure: "De (ville, code du pays)",
  arrival: "A (ville, code du pays)",
  packingType: "Type de packing",
  containerType: "Type de conteneur",
  quantity: "Quantité",
  container: "Conteneur",
  packageName: "Type de package",
  packageType: "Type de package",
  unitName: "Type d'unité",
  dimensions: "Dimensions",
  weight: "Poids",
  name: "Nom",
  specifics: "Spécificités",
  clientNumber: "Nom du client (ou numéro)",
  client: "Client",
  details: "Autres détails concernant la demande (Optionnel)",
  assignedManager: "Gestionnaire assigné",
  overviewTitle: "Historique des demandes de devis",
  assigneeId: "Id gestionnaire",
  requestQuoteId: "Id demande",
  assignDate: "Assigné le",
  unassignDate: "Révoqué le",
  assignee: "Gestionnaire",
  assigneeEmail: "Email du gestionnaire",
  search: "Rechercher",
  currentlyAssigned: "Assigné actuellement",
  createdDateStart: "Créé le",
  createdDateEnd: "Créé le (fin)",
  updatedDateStart: "Mis à jour le",
  updatedDateEnd: "Mis à jour le (fin)",
  newQuoteRequest: "Nouvelle demande de devis",
  justNow: "A l'instant",
  yesterday: "Hier",
  minutesAgo: "minutes, depuis",
  hoursAgo: "heures, depuis",
  daysAgo: "jours, depuis",
  monthsAgo: "mois, depuis",
  yearsAgo: "années, depuis",
  lastMonth: "le mois dernier",
  lastYear: "l'année dernière",
  requestsTitle: "Liste des demandes de devis",
  noAgentAssigned: "Pas de gestionnaire assigné",
  fromDotted: " de : ",
  status: "Statut",
  createdDate: "Date de création",
  offerId: "Id de l'offre",
  totalPrice: "Prix total",
  clientApproval: "Acceptation du client",
  noEmail: "Pas d'email",
  userAssignmentTitle: "Gérer les droits d'assignation",
  email: "Adresse mail",
  id: "Identifiant",
  canAssign: "Peut assigner",
  cannotAssign: "Ne peut assigner",
  cancelAssign: "Annuler le rôle d'assigné",
  assignAsManager: "Assigner comme gestionnaire",
  notAdministratorForAssign: "Vous ne pouvez pas gérer les utilisateurs car vous n'êtes pas administrateur. Veuillez contacter l'administrateur afin qu'il puisse vous accorder le rôle souhaité.",
  createRequest: "Créer la demande",
  requestNotAssignedCurrentUser: "Cette demande ne sera pas attribuée à l'utilisateur actuel, vous devez lui accorder l'autorisation dans",
  addContainer: "Ajouter le conteneur",
  add: "Ajouter",
  generatePriceOffer: "Génerer une offre de prix",
  editRequest: "Sauvegarder la demande",
  changeStatus: "Modifier le statut",
  addCommentNote: "Ajouter un commentaire/note",
  listNotes: "Liste des notes",
  updateManager: "Mettre à jour le gestionnaire",
  removeManager: "Retirer le gestionnaire",
  askInformation: "Demander des informations",
  pleaseFillForm: "Veuillez remplir le formulaire et cliquer sur le bouton pour envoyer votre message.",
  subject: "Sujet",
  content: "Contenu",
  send: "Envoyer",
  close: "Fermer",
  changeRequestStatus: "Modifier le statut de la demande",
  pleaseChooseOptions: "Veuillez choisir l'une des options suivantes comme statut de la demande.",
  statusList: "Liste des statuts",
  statusMessage: "Message de statut (ce message sera envoyé au demandeur, laisser vide pour ne pas envoyer de courrier)",
  validate: "Valider",
  pleaseFillAddNote: "Veuillez remplir le champ ci-dessous et cliquer sur le bouton pour ajouter une note.",
  generalNote: "Note générale",
  listNotesRequest: "Liste des notes de la demande N°",
  date: "Date",
  noteType: "Type de note",
  deleteNote: "Supprimer cette note",
  departureDate: "Date de départ",
  arrivalDate: "Date d'arrivée",
  loadingDate: "Date de chargement",
  loadingCity: "Ville de chargement",
  haulageType: "Type de transport (timing de chargement)",
  anyType: "Tout type",
  selectOfferMessage: "Vous pouvez sélectionner une offre en cliquant sur sa ligne. Vous devez sélectionner au moins un fret maritime pour votre offre.",
  listSeaFreightsPricingOffers: "Liste des offres de prix de fret maritime",
  listHaulagesPricingOffers: "Liste des offres de prix de transport",
  listMiscPricingOffers: "Liste des offres de prix diverses",
  selectedSeafreight: "Fret maritime sélectionné",
  selectedHaulage: "Transport sélectionné",
  selectedMisc: "Divers sélectionné",
  marginPercent: "Marge",
  reductionPercent: "Réduction",
  extraFee: "Frais supplémentaires",
  detailsOffer: "Contenu de l'offre",
  back: "Retour",
  skip: "Passer",
  sendOfferValidation: "Envoyer l'offre pour validation",
  nextStep: "Étape suivante",
  allTypes: "Tous les types",
  allStatus: "Tous les statuts",
  requestAssignedTo: "Cette demande sera assignée à",
  byDefault: "par défaut.",
  createNewRequest: "Créer une nouvelle demande",
  manageRequestQuote: "Gérer une demande de devis N°",
  doYouThinkInformation: "Pensez-vous que cette demande nécessite plus d'informations ?",
  askMoreInformation: "Demander plus d'informations",
  notDefined: "Non défini",
  approvePriceOffer: "Approuver une offre de prix",
  youSeeDetailsPriceOffer: "Vous pouvez voir les détails de notre offre de prix ci-dessous",
  selectedContainers: "Conteneurs sélectionnés",
  sentBackComment: "Commentaire à renvoyer",
  statusOfferIs: "Le statut de cette offre est",
  acceptOffer: "Accepter l'offre",
  rejectOffer: "Rejeter l'offre",
  carrier: "Transporteur",
  carrierAgent: "Agent du transporteur",
  departurePort: "Port de départ",
  arrivalPort: "Port d'arrivée",
  frequency: "Fréquence",
  transitTime: "Délai de mer",
  day: "jour",
  days: "jours",
  prices: "Fret par unité",
  haulier: "Transporteur routier",
  loadingPort: "Port de chargement",
  dischargePort: "Port de déchargement",
  freeTime: "Temps libre",
  multiStop: "Multi-arrêts",
  overtimeTariff: "Tarif des <br />heures supplémentaires",
  overtimeTariff2: "Tarif des heures supplémentaires",
  unitTariff: "Transport <br />par unité",
  validUntil: "Valide jusqu'au",
  destinationPort: "Port de destination",
  supplier: "Fournisseur",
  searchOffers: "Rechercher des offres",
  listOffers: "Liste des offres",
  sendOffer: "Envoyer une offre",
  loadingText: "Chargement en cours...",
  typeSomething: "Aucun résultat, tapez quelque chose...",
  typeSomethingSearch: "Tapez quelque chose pour rechercher...",
  generatedPriceOffers: "Offres de prix créées",
  errorHappened: "Une erreur s'est produite pendant l'opération.",
  requestEditedSuccess: "Votre demande a été modifiée avec succès.",
  managerAssignedRequest: "Le gestionnaire a été assigné à cette demande.",
  selectManagerFirst: "Vous devez d'abord sélectionner un gestionnaire de demande.",
  managerRemovedRequest: "Le gestionnaire a été retiré de cette demande.",
  requestStatusUpdated: "Le statut de votre demande a été mis à jour avec succès.",
  messageSuccessSent: "Le message a été envoyé avec succès.",
  contentEmpty: "Le champ de contenu est vide, veuillez le remplir.",
  commentSuccessAdded: "Le commentaire/note a été ajouté avec succès.",
  priceFieldsEmpty: "Vous devez choisir les conteneurs avant.",
  offerSuccessSent: "L'offre a été envoyée avec succès.",
  offerSuccessCreated: "L'offre a été créée avec succès.",
  byOmnifreight: "par Omnifreight",
  byClient: "par le client",
  statusIs: "Le statut de cette offre est",
  approveOffer: "Approuver et envoyer l'offre",
  updateOffer: "Mettre à jour l'offre",
  manageOffer: "Gérer l'offre",
  priceOfferRejected: "Votre offre de prix a été rejetée avec succès.",
  priceOfferApproved: "Votre offre de prix a été approuvée avec succès.",
  priceOfferUpdated: "Votre offre de prix a été mise à jour avec succès.",
  requestCreated: "Votre demande a été créée.",
  requestCreatedAssigned: "Votre demande a été créée avec succès.",
  fieldsEmpty: "Un ou plusieurs champs sont vides, veuillez remplir les champs de, à, nom du client et sélectionner au moins un conteneur.",
  emailNotValid: "L'adresse e-mail n'est pas valide, veuillez la vérifier.",
  errorHappenedRequest: "Une erreur s'est produite lors de l'envoi de votre demande.",
  needFillFields: "Vous devez remplir les champs nom de l'unité, poids et dimensions.",
  offerDeleted: "L'offre a été supprimée avec succès.",
  orderDeleted: "La commande a été supprimée avec succès.",
  confirmDeletion: "Confirmer la suppression",
  areYouSureDelete: "Êtes-vous sûr de vouloir supprimer cette offre ? Cette opération ne peut pas être annulée.",
  areYouSureDeleteOrder: "Êtes-vous sûr de vouloir supprimer cette commande ? Cette opération ne peut pas être annulée.",
  delete: "Supprimer",
  privacyPolicy: "Politique de confidentialité",
  introduction: "Introduction",
  dataPersonal: "Données personnelles",
  dataTransmitted: "Données transmises directement\nCes données sont les informations que vous nous fournissez directement, soit via un formulaire de contact, soit par contact direct par e-mail. Les champs \"prénom et nom\", \"société ou organisation\", numéro de téléphone et \"e-mail\" sont obligatoires dans le formulaire de contact.",
  dataCollectedAutomatically: "Données collectées automatiquement\nLors de vos visites, une fois que vous avez donné votre consentement, nous pouvons collecter des informations \"d'analyse Web\" concernant votre navigation, la durée de votre visite, votre adresse IP, et le type et la version de votre navigateur. La technologie utilisée est celle des cookies.",
  dataUsage: "Utilisation des données",
  dataDirectlyProvided: "Les données que vous nous fournissez directement sont utilisées pour vous contacter à nouveau et/ou en relation avec la demande que vous nous faites. Les données \"d'analyse Web\" sont collectées de manière anonyme (en enregistrant des adresses IP anonymes) par Google Analytics et nous permettent de mesurer l'audience de notre site Web, les visites et les erreurs éventuelles afin d'améliorer constamment l'expérience utilisateur. Ces données sont utilisées par OMNIFREIGHT, le responsable du traitement des données, et ne seront jamais transmises à des tiers ni utilisées à d'autres fins que celles détaillées ci-dessus.",
  legalBasis: "Base légale",
  dataCollectedConsent: "Les données personnelles ne sont collectées qu'après le consentement obligatoire de l'utilisateur. Ce consentement est valablement obtenu (boutons et cases à cocher), libre, clair et non équivoque.",
  retentionPeriod: "Durée de conservation",
  dataStoredMaxPeriod: "Les données seront conservées pendant une période maximale de 3 ans.",
  cookies: "Cookies",
  cookiesList: "Voici la liste des cookies utilisés et leur finalité:",
  googleAnalyticsCookies: "Cookies Google Analytics (liste exhaustive) : Analyse Web",
  otherCookies: "Autres cookies : Suivent votre acceptation des cookies pour éviter de vous déranger lors de votre prochaine visite.",
  rightsPersonalData: "Vos droits concernant les données personnelles",
  accessModificationDeletion: "Vous avez le droit d'accéder à vos données personnelles, de demander leur modification ou leur suppression. Vous pouvez également retirer votre consentement pour le traitement de vos données.",
  dataProtectionContact: "Contact du délégué à la protection des données",
  emailContact: "E-mail : transport@omnifreight.eu - Numéro de téléphone : (+32)3.295.38.82",
  downloadBrochure: "Téléchargez notre brochure",
  pleaseFillFormRequest: "Veuillez remplir le formulaire et cliquer sur le bouton pour envoyer une demande de devis.",
  download: "Télécharger",
  congratulations: "Félicitations !",
  successRequestQuoteMessage: "Votre demande a été envoyée avec succès. Veuillez vérifier votre e-mail ou votre dossier de courriers indésirables pour obtenir votre numéro de suivi. Nous vous répondrons bientôt avec le coût et les meilleures options pour votre expédition.",
  checkCaptcha: "Vous devez cocher le captcha avant d'envoyer votre demande.",
  seaShipments: "Expéditions maritimes",
  airShipments: "Expéditions aériennes",
  becomeReseller: "Devenir revendeur",
  jobOpportunities: "Opportunités d'emploi",
  iUnderstood: "J'ai compris",
  websiteUsesCookies: "Ce site utilise des cookies. En continuant à utiliser ce site, vous acceptez leur utilisation. Pour plus de détails, veuillez consulter notre",
  login: "Connexion",
  bannerTitle: "Nous organisons le transport de vos cargaisons vers l'Afrique depuis le monde!",
  requestQuote: "Demander un devis",
  contactManager: "Contacter un gestionnaire",
  bannerMessage1: "Souhaitez-vous recevoir un devis pour l'expédition de marchandises ?",
  bannerMessage2: "Souhaitez-vous qu'un gestionnaire d'Omnifreight vous contacte ?",
  bannerMessage3: "Vous voulez en savoir plus sur Omnifreight ?",
  cargoPickup: "Où voulez-vous que nous prenions en charge votre cargaison ?",
  cargoDeliver: "Où voulez-vous que nous livrions votre cargaison ?",
  cargoTypeShip: "Quel type de cargaison souhaitez-vous expédier ?",
  numberUnitsShip: "Combien d'unités souhaitez-vous expédier ?",
  shareOtherDetails: "Voulez-vous partager d'autres détails concernant vos besoins ? (Facultatif)",
  continue: "Continuer",
  pleaseProvideContact: "Veuillez fournir votre numéro WhatsApp et/ou votre adresse e-mail",
  topicsInformation: "Quels sujets vous intéressent ?",
  enterDetails: "Saisissez les détails de votre besoin",
  viewProfile: "Voir le profil",
  inviteMembers: "Inviter des membres",
  pageNotExist: "Désolé, cette page n'existe pas.",
  requestTracking: "Suivi de la demande",
  trackingCodeNotDefined: "Le code de suivi n'est pas défini.",
  detail: "Détails",
  requestDate: "Date de la demande",
  requestStatus: "Statut de la demande",
  weAreWorkingOnRequest: "Nous travaillons sur votre demande et vous répondrons bientôt avec le coût et les meilleures options pour votre expédition.",
  trackingNumber: "Numéro de suivi",
  trackMyRequest: "Suivre ma demande",
  customerTitle: "Ce que pensent nos clients...",
  customerRole1: "Importateur en Mauritanie, Sénégal, Côte d'Ivoire, République du Congo et Angola.",
  customerMessage1: "Je suis très satisfait du travail d'Omnifreight et chaque jour je suis plus motivé à travailler avec eux. C'est une société honnête et digne et ils sont devenus comme des frères.",
  customerRole2: "Importateur en République du Congo.",
  customerMessage2: "Merci pour votre réactivité et votre professionnalisme. Toujours satisfait d'Omnifreight.",
  assigneeCreateSuccess: "Votre nouveau bénéficiaire a été créé avec succès.",
  cantFindRequestAssigned: "Nous ne pouvons pas trouver de demande qui vous a été attribuée.",
  noRequests: "Aucune demande n'a été trouvée.",
  operationSuccess: "Votre opération a été effectuée avec succès.",
  informationReceived: "Merci, nous avons bien reçu vos informations.",
  itsEaseFillForm: "C'est simple, il vous suffit de remplir le formulaire et de cliquer sur \"continuer\" en bas à droite pour envoyer une demande de devis.",
  requestSuccessSent: "Votre demande a été envoyée avec succès.",
  searchResultsFor: "Résultats de recherche pour",
  listRequestsQuote: "Liste des demandes de devis",
  allRightsReserved: "Tous droits réservés",
  fcl: "FCL",
  breakbulk: "Breakbulk/LCL",
  roro: "Unit RoRo",
  descriptionEnAttente: "En attente de traitement",
  descriptionValider: "Devis validé par l'employé",
  descriptionRejeter: "Devis rejeté par l'employé",
  descriptionEnCoursDeTraitement: "Devis en cours de traitement",
  descriptionEnTransit: "Marchandise en cours de transport",
  descriptionEnDouane: "Marchandise en cours de dédouanement",
  descriptionLivraisonEnCours: "Marchandise en cours de livraison",
  descriptionLivre: "Marchandise livrée au client",
  descriptionAnnule: "La demande de devis a été annulée",
  descriptionRetour: "Marchandise retournée à l'expéditeur",
  descriptionProbleme: "Problème rencontré lors du transport, à résoudre",
  descriptionEnAttenteDeFacturation: "En attente de facturation après livraison",
  haulageType1: "Sur remorque, chargement direct",
  haulageType2: "Sur remorque, chargement avec intervalle",
  haulageType3: "Chargeur latéral, chargement direct",
  haulageType4: "Chargeur latéral, chargement avec intervalle, de la remorque au sol",
  haulageType5: "Chargeur latéral, chargement avec intervalle, du sol à la remorque",
  searchSeafreight: "Chercher fret",
  selectSeafreight: "Choisir fret",
  searchHaulage: "Chercher transport",
  selectHaulage: "Choisir transport",
  selectMisc: "Choisir divers",
  optional: "Optionnel",
  hour: "heure",
  hours: "heures",
  noResults: "Aucun résultat n'a été trouvé",
  every: "Tous les",
  EUR: '€',
  length: "Longueur",
  width: "Largeur",
  height: "Hauteur",
  fieldNeedTobeFilled: "Vous devez remplir les champs requis",
  clientName: "Nom du client",
  costPrices: "Coût par unité",
  quoteNumber: "Numéro de devis",
  lastUpdated: "Mis à jour le",
  comment: "Commentaire/remarque",
  noComment: "Sans commentaire",
  seafreights: "Frêts maritimes",
  listSeafreights: "Liste des prix de frêts maritimes",
  created: "Créé le",
  deleteRow: "Supprimer cette ligne",
  editRow: "Modifier cette ligne",
  createRow: "Ajouter une ligne",
  areYouSureDeleteRow: "Voulez vous réellement supprimer cette ligne? Attention, cette action est définitive.",
  accept: "Confirmer",
  currency: "Devise",
  rowDeletedSuccess: "Cette ligne a été supprimée avec succès",
  rowDeletedError : "Une erreur s'est produite, le système n'a pas réussi à supprimer cette ligne",
  inDays: "en jours",
  everyxDays: "tous les x jours",
  serviceName: "Service",
  containers: "Conteneurs",
  successCreated: "L'élément a été créé / modifié avec succès",
  price: "Prix",
  haulages: "Transports routiers",
  listHaulages: "Liste des prix de transport routier",
  emptyPickupDepot: "Dépôt de ramassage vide",
  unitTariff2: "Tarif unitaire",
  freeTime2: "Temps libre au lieu de chargement (en heures)",
  miscellaneous: "Services divers",
  labelEnAttente: "En attente",
  labelValider: "Validé",
  labelRejeter: "Rejeté",
  labelEnCoursDeTraitement: "En cours de traitement",
  labelEnTransit: "En transit",
  labelEnDouane: "En douane",
  labelLivraisonEnCours: "Livraison en cours",
  labelLivre: "Livré",
  labelAnnule: "Annulé",
  labelRetour: "Retourné",
  labelProbleme: "Problème",
  labelEnAttenteDeFacturation: "En attente de facturation",
  fileSent: "Fichiers joints",
  fieldEmptySeafreight : "Vous devez remplir tous les champs obligatoires et choisir au moins un service",
  fieldsEmptyHaulage: "Vous devez remplir tous les champs obligatoires",
  listMiscellaneous: "Liste de prix de divers services",
  newSeafreightPrice: "Ajouter prix de frêt maritime",
  newHaulagePrice: "Ajouter prix de transport routier",
  newMiscellaneousPrice: "Ajouter prix de service divers",
  listServices: "Liste des services",
  withShipment: "Avec expédition",
  mailLanguage: "Message initial dans la langue",
  youNeedSelectHaulage : "Vous devez sélectionner une offre de transport avant de passer à l'étape suivante",
  youNeedSelectSeafreight : "Vous devez sélectionner une offre de fret maritime avant de passer à l'étape suivante",
  msgValidateHaulageStep: "Vous devez remplir les champs de chargement de la ville et du type de transport",
  msgValidateStartStep: "Les champs date de départ, conteneurs et port de destination ne peuvent pas être vides, remplissez-les",
  pricing: "Gestion des prix",
  assigneeCreatedSuccess: "Succès de l'opération",
  messageModal: "Message",
  reload: "Recharger",
  labelNew: "Nouveau",
  requestHaulagePrice: "Demander un prix au transporteur",
  requestSeafreightPrice: "Demander un prix au transporteur",
  priceRequestFCL: "Demande de prix FCL",
  priceRequestHaulage: "Demande de prix de transport",
  portLoading: "Port de chargement",
  portDischarge: "Port de déchargement",
  commodities: "Commodités",
  etd: "Date prévue de départ",
  recipients: "Destinataires",
  deliveryPort: "Port de chargement",
  errorSelectRecipient: "Vous devez sélectionner au moins un destinataire avant d'envoyer votre mail",
  mailSentTo: "Mail envoyé à : ",
  templates: "Modèles",
  listTemplates: "Liste des modèles",
  newTemplate: "Nouveau modèle",
  createdBefore: "Créé avant le",
  createdAfter: "Créé après le",
  currentVersion: "Version actuelle",
  tags: "Etiquettes",
  listContainers: "Liste des conteneurs",
  containersQuantities: "Conteneurs et quantités",
  variables: "Ajouter une variable",
  errorHappenedVersion: "Une erreur s'est produite, essayez de changer la version avant de valider",
  successEdited: "L'élément a été modifié avec succès",
  cantDelete: "Vous ne pouvez pas supprimer cet élément",
  pricesContainers: "Prix par conteneurs",
  deleteRowHaulage: "Supprimer prix de transport",
  editRowHaulage: "Modifier prix de transport",
  createRowHaulage: "Ajouter prix de transport",
  deleteRowSeafreight: "Supprimer prix de frêt maritime",
  editRowSeafreight: "Modifier prix de frêt maritime",
  createRowSeafreight: "Ajouter prix de frêt maritime",
  deleteRowMisc: "Supprimer prix de service divers",
  editRowMisc: "Modifier prix de service divers",
  createRowMisc: "Ajouter prix de service divers",
  selectedTemplate: "Choisir un modèle",
  reloadIndication: "Veuillez cliquer sur le bouton Recharger si vous voulez actualiser les données après vos modifications",
  addressCountry: "Adresse du pays",
  countryCode: "Code du pays",
  createNewPort: "Nouveau port",
  createNewContact: "Nouveau contact",
  createNewCarrier: "Nouveau transporteur maritime",
  createNewHaulier: "Nouveau transporteur routier",
  createNewHaulage: "Nouveau prix de transport routier",
  priceType: "Type de prix",
  lumpSum: "Marge forfaitaire",
  margin: "Marge",
  salesPrice: "Sales price",
  totalPurchase: "Coût total",
  copyRowSeafreight: "Copier prix de frêt maritime",
  messageOkGeneralMiscs: "Vos services miscs généraux ont été enregistrés!",
  included: "inclus",
  seafreight: "Frêt maritime",
  haulage: "Transport routier",
  masterdata: "Masterdata",
  services: "Services",
  servicesTypesId: "Types de services",
  newService: "Créer un nouveau service",
  deleteRowService: "Supprimer un service",
  editRowService: "Modifier un service",
  createRowService: "Créer un service",
  hscodes: "HS Codes",
  newHSCode: "Nouveau HS code",
  deleteRowHSCode: "Supprimer un HS code",
  editRowHSCode: "Modifier un HS code",
  createRowHSCode: "Créer una HS code",
  hscodeName: "Nom du HS Code",
  listHSCodes: "Liste de HS Codes",
  products: "Produits",
  newProduct: "Nouveau produit",
  deleteRowProduct: "Supprimer un produit",
  editRowProduct: "Modifier un produit",
  createRowProduct: "Créer un produit",
  productName: "Nom du produit",
  listProducts: "Liste de produits",
  listPorts: "Liste de ports",
  newPort: "Créer un nouveau port",
  deleteRowPort: "Supprimer un port",
  editRowPort: "Modifier un port",
  createRowPort: "Créer un port",
  portName: "Nom du port",
  ports: "Ports",
  listContacts: "Liste de contacts",
  newContact: "Créer un nouveau contact",
  deleteRowContact: "Supprimer un contact",
  editRowContact: "Modifier un contact",
  createRowContact: "Créer un contact",
  contactName: "Nom du contact",
  contacts: "Contacts",
  categories: "Catégories",
  contactNumber: "Numéro",
  phone: "Numéro de téléphone",
  country: "Pays",
  serviceTypesId: "Types de service",
  newHaulage: "Nouveau prix de transport",
  newSeafreight: "Nouveau prix de frêt maritime",
  rateRequest: "DEMANDE DE PRIX",
  rateRequestHaulage: "DEMANDE DE PRIX DE TRANSPORT",
  purchasePrice: "Prix d'achat",
  profit: "Profit",
  salePrice: "Prix de vente",
  listFiles: "Liste de fichiers",
  newFile: "Créer un nouveau fichier",
  deleteRowFile: "Supprimer un fichier",
  editRowFile: "Modifier un fichier",
  createRowFile: "Créer un fichier",
  uploadFile: "Ajouter un fichier",
  additionals: "supplémentaires", 
  customerRequest: "Demande du client", 
  loadingOf: "Chargement de ",
  includedForEachContainer: " heures inclus pour chaque conteneur, ensuite de ", 
  byHourIndivisible: " EUR par heure indivisible.", 
  or: "OU",
  offer: "Offre", 
  files: "Fichier",
  fileName: "Nom du fichier",
  contentType: "Type de contenu", 
  size: "Taille en octets",
  uploadedAt: "Téléversé le",
  downloadFile: "Télécharger le fichier",
  file: "Fichier",
  pendingRequests: "Demandes en cours",
  listPendingRequests: "Liste des demandes en cours",
  createOffer: "Créer une offre",
  trip: "Trajet",
  from: "Départ",
  to: "Arrivée",
  messageSentCustomer: "Message envoyé au client",
  newQuoteOffer: "Nouvelle offre de devis", 
  yourAttachments: "Ce message sera envoyé avec les pièces jointes suivantes", 
  noAttachments: "Ce message n'a pas de pièces jointes",
  updateOption: "Mettre à jour",
  selectOption: "Choisir",
  deleteOption: "Supprimer",
  newOption: "Nouvelle option",
  compareOptions: "Comparer les options",
  youNeedOptions: "Vous devez enregistrer des options à envoyer",
  saveOption: "Enregistrer l'option",
  resendOffer: "Renvoyer l'offre",
  goToOptions: "Aller aux options",
  selectOptionOffer: "Je choisis l'offre",
  refuseOffers: "Refuser les offres",
  systemLanguage: "Langue du mail",
  langtextfr: "Français",
  langtexten: "Anglais",
  confirmationOffer: "Confirmation de l'offre sélectionnée",
  endMailWord: "Cordialement",
  hello: "Bonjour",
  confirmationOfferText: "Nous avons bien pris en compte votre choix et nous sommes heureux de vous confirmer que nous allons procéder à la réservation de votre expédition selon les modalités suivantes : ",
  confirmationOfferThanks: "Je vous remercie d'avoir pris le temps de choisir l'offre qui convient le mieux à vos besoins pour l'expédition de votre cargaison.",
  miscDetails: "Détails des services",
  seafreightDetails: "Détails des frets",
  haulageTariff: "Tarif du transport routier",
  seafreightTariff: "Tarif du fret maritime",
  miscTariff: "Tarif du service",
  totalUnitPrice: "Prix unitaire total",
  details2: "Détails",
  category: "Catégorie",
  serviceItem: "Element de service",
  value: "Valeur",
  simpleSearch: "Recherche simple",
  advancedSearch: "Recherche avancée",
  trackingOptions: "Vous pouvez obtenir des informations sur votre commande à l'aide de ce numéro : ",
  orders: "Commandes",
  listOrders: "Liste des commandes",
  orderNumber: "N° de la commande",
  fiscalYear: "Année fiscale",
  orderDate: "Date",
  seller: "Vendeur",
  buyer: "Acheteur",
  customer: "Client",
  textEtd: "ETD",
  textEta: "ETA",
  ship: "Vaisseau", 
  shippingLine: "Ligne maritime",
  ratio: "Ratio",
  vessel: "Navire", 
  ships: "Navires",
  newShip: "Nouveau navire",
  deleteRowShip: "Supprimer un navire",
  editRowShip: "Modifier un navire",
  createRowShip: "Créer un navire",
  shipName: "Nom du navire",
  listShips: "Liste de navires",
};
